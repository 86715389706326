<template>
  <v-combobox
    :value="items"
    :menu-props="{ value: false }"
    class="base-input combobox"
    chips
    :solo="!outlined"
    :flat="!outlined"
    :outlined="outlined"
    append-icon=""
    :placeholder="placeholder"
    :no-filter="true"
    multiple
    :filter="() => false"
    @input="onInput"
    hide-details
  >
    <template v-slot:selection="{ item }">
      <v-chip label class="combobox_chip">
        {{ item }}
        <v-icon class="combobox_chip-icon" small @click="deleteItem(item)">mdi-close</v-icon>
      </v-chip>
    </template>
  </v-combobox>
</template>
<script>
export default {
  name: "Combobox",
  props: {
    placeholder: String,
    items: { required: true, type: Array },
  },
  data: () => ({
    outlined: false,
  }),
  methods: {
    onInput(input) {
      this.$emit("change", input);
      this.$emit("input", input);
    },
    deleteItem(item) {
      const newItems = this.items.filter((i) => i !== item);
      this.$emit("change", newItems);
      this.$emit("input", newItems);
    },
  },
};
</script>

<style lang="scss">
.v-autocomplete:not(.v-input--is-focused).v-select--chips input {
  max-height: 25px !important;
}
</style>
